<template>
  <div>
    <h2>Dag totalen &mdash; Grafiek</h2>
    <div class="mt-8 w-full" :key="JSON.stringify(chartValues)">
      <ChartsBar label="Totaal per dag" :categories="chartCategories" :values="chartValues" :options="options" />
    </div>
  </div>
</template>

<script setup>
import { secondsToHMSWritten } from '@/functions/formatDate'
import { defineProps, toRefs, defineAsyncComponent, computed } from 'vue'

const ChartsBar = defineAsyncComponent(() => import('@/components/Charts/Bar.vue'))

const props = defineProps({
  items: {
    type: Array,
    default() { return [] },
  },
})

const { items } = toRefs(props)

const chartCategories = computed(() => {
  if (!items.value) return []
  return items.value.map((item, index) => item.day || (index + 1))
})

const chartValues = computed(() => {
  if (!items.value) return []
  return items.value.map(item => item.totaal <= 0 ? 0 : item.totaal / 60 / 60)
})

const options = computed(() => ({
  chart: {
    type: 'bar',
    height: 350,
    zoom: false,
  },

  toolbar: {
    tools: {
      download: '<i class="fas fa-save" width="50" height="20" />',
    },
  },

  dataLabels: {
    enabled: true,
    formatter(val) {
      const seconds = Number(val) * 60 * 60
      if (seconds < 1) return ''
      return secondsToHMSWritten(seconds)
    },
    style: {
      fontSize: '8px',
      backgroundColor: '#FFF',
      colors: ['#304758'],
    },
  },

  xaxis: {
    type: 'category',
    tickPlacement: 'on',
    categories: chartCategories.value,
    overwriteCategories: chartCategories.value,
    tickAmount: chartCategories.value.length,
    min: chartCategories.value[0],
    max: chartCategories.value[chartCategories.value.length - 1],
  },

  yaxis: {
    enabled: true,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: false,
    },
  },

  tooltip: {
    y: {
      formatter(val) {
        const seconds = Number(val) * 60 * 60
        if (seconds < 1) return ''
        return secondsToHMSWritten(seconds)
      },
    },
  },
}))
</script>
